import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import axios from "axios";
import Footer from "./footer";
import HeaderAuth from "./header_auth";
import { useTranslation } from 'react-i18next'; // Import the useTranslation hook

export default function AuthForm() {
    const { t } = useTranslation(); // Initialize translation hook
    const [isSignUp, setIsSignUp] = useState(false);
    const [isAgreed, setIsAgreed] = useState(false);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();

    const isFormValid = username && password && isAgreed;

    const handleSignIn = async (e) => {
        e.preventDefault();
        if (!isFormValid) return;

        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/auth/signin`,
                { username, password },
                { withCredentials: true },
            );
            if (response.status === 200) {
                navigate('/');  // Redirect to private page
            } else {
                alert(t("auth.incorrectCredentials")); // Use translation key
            }
        } catch (error) {
            alert(t("auth.incorrectCredentials")); // Use translation key
        }
    };

    const handleSignUp = async (e) => {
        e.preventDefault();
        if (!isFormValid) return;

        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/auth/signup`, {
                username,
                password
            }, { withCredentials: true });
            if (response.status === 200) {
                navigate('/');  // Redirect to private page
            } else {
                alert(t("auth.userExists")); // Use translation key
            }
        } catch (error) {
            alert(t("auth.userExists")); // Use translation key
        }
    };

    const toggleForm = () => {
        setIsSignUp(!isSignUp);
    };

    const handleCheckboxChange = (e) => {
        setIsAgreed(e.target.checked);
    };

    const handleUsernameChange = (e) => {
        setUsername(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    return (
        <>
            <HeaderAuth />
            <div className="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
                <div className="mx-auto max-w-2xl text-center px-4">
                    <h1 className="text-5xl font-extrabold tracking-tight text-gray-900 sm:text-6xl lg:text-7xl">
                        {t("welcome.lexihHero")} <span role="img" aria-label="hero">🦸</span>
                    </h1>
                    <p className="mt-4 text-lg leading-6 text-gray-600 sm:text-xl lg:text-2xl">
                        {t("welcome.description")} {/* Add a description key if needed */}
                    </p>
                </div>
                <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                    <h2 id="form-title"
                        className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                        {isSignUp ? t("auth.signUpTitle") : t("auth.signInTitle")} {/* Use translation keys */}
                    </h2>
                </div>

                <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                    {isSignUp ? (
                        <form id="sign-up-form" className="space-y-6" onSubmit={handleSignUp}>
                            <div>
                                <label htmlFor="sign-up-username"
                                    className="block text-sm font-medium leading-6 text-gray-900">{t("auth.username")}</label>
                                <div className="mt-2">
                                    <input id="sign-up-username" name="username" type="text" autoComplete="username"
                                        required
                                        value={username}
                                        onChange={handleUsernameChange}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                                </div>
                            </div>

                            <div>
                                <label htmlFor="sign-up-password"
                                    className="block text-sm font-medium leading-6 text-gray-900">{t("auth.password")}</label>
                                <div className="mt-2">
                                    <input id="sign-up-password" name="password" type="password"
                                        autoComplete="new-password"
                                        required
                                        value={password}
                                        onChange={handlePasswordChange}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                                </div>
                            </div>

                            <div className="flex items-center">
                                <input
                                    id="sign-up-agreement"
                                    name="agreement"
                                    type="checkbox"
                                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                    onChange={handleCheckboxChange}
                                />
                                <label htmlFor="sign-up-agreement" className="ml-2 block text-sm text-gray-900">
                                    {t("auth.agreementText")} <a href="/privacy-policy" className="text-indigo-600 hover:text-indigo-500">{t("auth.privacyPolicy")}</a> {t("auth.and")} <a href="/terms-of-use" className="text-indigo-600 hover:text-indigo-500">{t("auth.termsOfUse")}</a>
                                </label>
                            </div>

                            <div>
                                <button type="submit"
                                    disabled={!isFormValid}
                                    className={`flex w-full justify-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ${isFormValid ? 'bg-indigo-600 hover:bg-indigo-500' : 'bg-gray-400 cursor-not-allowed'
                                        }`}>
                                    {t("auth.signUpButton")} {/* Use translation key */}
                                </button>
                            </div>
                        </form>
                    ) : (
                        <form id="sign-in-form" className="space-y-6" onSubmit={handleSignIn}>
                            <div>
                                <label htmlFor="sign-in-username"
                                    className="block text-sm font-medium leading-6 text-gray-900">{t("auth.username")}</label>
                                <div className="mt-2">
                                    <input id="sign-in-username" name="username" type="text" autoComplete="username"
                                        required
                                        value={username}
                                        onChange={handleUsernameChange}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                                </div>
                            </div>

                            <div>
                                <label htmlFor="sign-in-password"
                                    className="block text-sm font-medium leading-6 text-gray-900">{t("auth.password")}</label>
                                <div className="mt-2">
                                    <input id="sign-in-password" name="password" type="password"
                                        autoComplete="current-password" required
                                        value={password}
                                        onChange={handlePasswordChange}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                                </div>
                            </div>

                            <div className="flex items-center">
                                <input
                                    id="sign-in-agreement"
                                    name="agreement"
                                    type="checkbox"
                                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                    onChange={handleCheckboxChange}
                                />
                                <label htmlFor="sign-in-agreement" className="ml-2 block text-sm text-gray-900">
                                    {t("auth.agreementText")} <a href="/privacy-policy" className="text-indigo-600 hover:text-indigo-500">{t("auth.privacyPolicy")}</a> {t("auth.and")} <a href="/terms-of-use" className="text-indigo-600 hover:text-indigo-500">{t("auth.termsOfUse")}</a>
                                </label>
                            </div>

                            <div>
                                <button type="submit"
                                        disabled={!isFormValid}
                                        className={`flex w-full justify-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ${
                                            isFormValid ? 'bg-indigo-600 hover:bg-indigo-500' : 'bg-gray-400 cursor-not-allowed'
                                        }`}>
                                    {t("auth.signInButton")} {/* Use translation key */}
                                </button>
                            </div>
                        </form>
                    )}

                    <p className="mt-10 text-center text-sm text-gray-500">
                        <span id="toggle-form-text">{isSignUp ? t("auth.toggleTextSignIn") : t("auth.toggleTextSignUp")}</span>
                        <a href="#" id="toggle-form-link"
                           className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
                           onClick={toggleForm}>
                            {isSignUp ? t("auth.toggleLinkSignIn") : t("auth.toggleLinkSignUp")}
                        </a>
                    </p>
                </div>
            </div>
            <Footer />
        </>
    );
}
