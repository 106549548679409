import React from 'react';
import { useNavigate } from 'react-router-dom';

function PrivacyPolicy() {
    const navigate = useNavigate();
    const handleBackClick = () => {
        navigate('/');
    };

    return (
        <div className="min-h-screen bg-gray-50 p-6">
            <div className="max-w-4xl mx-auto bg-white shadow-md rounded-lg p-6">
                <button
                    onClick={handleBackClick}
                    className="mb-4 px-4 py-2 text-sm font-medium text-white bg-blue-500 rounded hover:bg-blue-600"
                >
                    Back to Home
                </button>
                <h1 className="text-3xl font-bold text-gray-800 mb-6">Privacy Policy</h1>
                <div className="space-y-6">
                    <section>
                        <h2 className="text-xl font-semibold text-gray-700">1. Terms</h2>
                        <p className="text-gray-600 mt-2">
                            These terms and conditions outline the rules and regulations for the use of Lexihero's Website,
                            located at{' '}
                            <a
                                href="https://lexihero.com"
                                className="text-blue-500 underline hover:text-blue-700"
                            >
                                https://lexihero.com
                            </a>
                            .
                        </p>
                        <p className="text-gray-600 mt-2">
                            By accessing this website we assume you accept these terms and conditions. Do not continue to
                            use Lexihero if you do not agree to take all of the terms and conditions stated on this page.
                        </p>
                        <p className="text-gray-600 mt-2">
                            The following terminology applies to these Terms and Conditions, Privacy Statement and
                            Disclaimer Notice and all Agreements: "Client", "You" and "Your" refers to you, the person log
                            on this website and compliant to the Company's terms and conditions. "The Company",
                            "Ourselves", "We", "Our" and "Us", refers to our Company. "Party", "Parties", or "Us", refers
                            to both the Client and ourselves. All terms refer to the offer, acceptance and consideration of
                            payment necessary to undertake the process of our assistance to the Client in the most
                            appropriate manner for the express purpose of meeting the Client's needs in respect of
                            provision of the Company's stated services, in accordance with and subject to, prevailing law
                            of af. Any use of the above terminology or other words in the singular, plural, capitalization
                            and/or he/she or they, are taken as interchangeable and therefore as referring to same.
                        </p>
                    </section>
                    <section>
                        <h2 className="text-xl font-semibold text-gray-700">2. Cookies</h2>
                        <p className="text-gray-600 mt-2">
                            We employ the use of cookies. By accessing Lexihero, you agreed to use cookies in agreement
                            with the Lexihero's Privacy Policy.
                        </p>
                        <p className="text-gray-600 mt-2">
                            Most interactive websites use cookies to let us retrieve the user's details for each visit.
                            Cookies are used by our website to enable the functionality of certain areas to make it easier
                            for people visiting our website. Some of our affiliate/advertising partners may also use
                            cookies.
                        </p>
                    </section>
                    <section>
                        <h2 className="text-xl font-semibold text-gray-700">3. License</h2>
                        <p className="text-gray-600 mt-2">
                            Unless otherwise stated, Lexihero and/or its licensors own the intellectual property rights for
                            all material on Lexihero. All intellectual property rights are reserved. You may access this
                            from Lexihero for your own personal use subjected to restrictions set in these terms and
                            conditions.
                        </p>
                        <ul className="list-disc list-inside mt-2 text-gray-600">
                            <li>Republish material from Lexihero</li>
                            <li>Sell, rent or sub-license material from Lexihero</li>
                            <li>Reproduce, duplicate or copy material from Lexihero</li>
                            <li>Redistribute content from Lexihero</li>
                        </ul>
                        <p className="text-gray-600 mt-2">
                            This Agreement shall begin on the date hereof. Our Terms and Conditions were created with the
                            help of the{' '}
                            <a
                                href="https://www.termsandconditionsgenerator.com/"
                                className="text-blue-500 underline hover:text-blue-700"
                            >
                                Free Terms and Conditions Generator
                            </a>
                            .
                        </p>
                    </section>
                    <section>
                        <h2 className="text-xl font-semibold text-gray-700">4. Content Liability</h2>
                        <p className="text-gray-600 mt-2">
                            We shall not be hold responsible for any content that appears on your Website. You agree to
                            protect and defend us against all claims that is rising on your Website. No link(s) should
                            appear on any Website that may be interpreted as libelous, obscene or criminal, or which
                            infringes, otherwise violates, or advocates the infringement or other violation of, any third
                            party rights.
                        </p>
                    </section>
                    <section>
                        <h2 className="text-xl font-semibold text-gray-700">5. Reservations of Rights</h2>
                        <p className="text-gray-600 mt-2">
                            We reserve the right to request that you remove all links or any particular link to our Website.
                            You approve to immediately remove all links to our Website upon request. We also reserve the
                            right to amend these terms and conditions and its linking policy at any time. By continuously
                            linking to our Website, you agree to be bound to and follow these linking terms and conditions.
                        </p>
                    </section>
                    <section>
                        <h2 className="text-xl font-semibold text-gray-700">6. Removal of Links</h2>
                        <p className="text-gray-600 mt-2">
                            If you find any link on our Website that is offensive for any reason, you are free to contact
                            and inform us any moment. We will consider requests to remove links but we are not obligated to
                            or so or to respond to you directly.
                        </p>
                        <p className="text-gray-600 mt-2">
                            We do not ensure that the information on this website is correct, we do not warrant its
                            completeness or accuracy; nor do we promise to ensure that the website remains available or
                            that the material on the website is kept up to date.
                        </p>
                    </section>
                    <section>
                        <h2 className="text-xl font-semibold text-gray-700">7. Disclaimer</h2>
                        <p className="text-gray-600 mt-2">
                            To the maximum extent permitted by applicable law, we exclude all representations, warranties
                            and conditions relating to our website and the use of this website. Nothing in this disclaimer
                            will:
                        </p>
                        <ul className="list-disc list-inside mt-2 text-gray-600">
                            <li>limit or exclude our or your liability for death or personal injury;</li>
                            <li>limit or exclude our or your liability for fraud or fraudulent misrepresentation;</li>
                            <li>
                                limit any of our or your liabilities in any way that is not permitted under applicable law;
                                or
                            </li>
                            <li>
                                exclude any of our or your liabilities that may not be excluded under applicable law.
                            </li>
                        </ul>
                        <p className="text-gray-600 mt-2">
                            The limitations and prohibitions of liability set in this Section and elsewhere in this
                            disclaimer: (a) are subject to the preceding paragraph; and (b) govern all liabilities arising
                            under the disclaimer, including liabilities arising in contract, in tort and for breach of
                            statutory duty.
                        </p>
                        <p className="text-gray-600 mt-2">
                            As long as the website and the information and services on the website are provided free of
                            charge, we will not be liable for any loss or damage of any nature.
                        </p>
                    </section>
                </div>
            </div>
        </div>
    );
}

export default PrivacyPolicy;
