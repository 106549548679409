import { useTranslation } from 'react-i18next'; // Import the useTranslation hook

// https://flowbite.com/docs/components/footer/#footer-with-logo
export default function Footer() {
    const { t } = useTranslation(); // Initialize the translation hook
    return (
        <footer className="bg-white rounded-lg shadow dark:bg-gray-900 m-4">
            <div className="w-full max-w-screen-xl mx-auto p-4 md:py-8">
                <div className="flex justify-center mb-6">
                    <ul className="flex flex-wrap justify-center items-center text-sm font-medium text-gray-500 dark:text-gray-400">
                        <li>
                            <a href="/privacy-policy" className="hover:underline me-4 md:me-6">{t('footer.privacyPolicy')}</a>
                        </li>
                        <li>
                            <a href="/terms-of-use" className="hover:underline me-4 md:me-6">{t('footer.tos')}</a>
                        </li>
                        <li>
                            <a href="mailto:contact@lexihero.com" className="hover:underline">{t('footer.contact')}</a>
                        </li>
                    </ul>
                </div>
                <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
                <span className="block text-sm text-gray-500 text-center dark:text-gray-400">
                    © 2024 <a href="/" className="hover:underline">LexiHero</a>. All Rights Reserved.
                </span>
            </div>
        </footer>
    );
}