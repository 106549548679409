import React, { useEffect, useState } from 'react';
import axiosInstance from "./axiosInstance";
import { useTranslation } from 'react-i18next';

export default function Translator() {
    const [wordInput, setWordInput] = useState('');
    const [translateFrom, setTranslateFrom] = useState(localStorage.getItem('translateFrom') || 'english');
    const [translateTo, setTranslateTo] = useState(localStorage.getItem('translateTo') || 'polish');
    const [savingEnabled, setSavingEnabled] = useState(localStorage.getItem('savingEnabled') === "true");
    const [collections, setCollections] = useState([]);
    const [selectedCollection, setSelectedCollection] = useState(
        localStorage.getItem('selectedCollection') || 0
    );
    const [youglishLanguage, setYouglishLanguage] = useState(localStorage.getItem('youglishLanguage') || 'polish');
    const [translation, setTranslation] = useState(null);
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();

    const languageOptions = {
        arabic: t('languages.arabic'),
        chinese: t('languages.chinese'),
        dutch: t('languages.dutch'),
        english: t('languages.english'),
        french: t('languages.french'),
        german: t('languages.german'),
        greek: t('languages.greek'),
        hebrew: t('languages.hebrew'),
        italian: t('languages.italian'),
        japanese: t('languages.japanese'),
        korean: t('languages.korean'),
        polish: t('languages.polish'),
        portuguese: t('languages.portuguese'),
        russian: t('languages.russian'),
        spanish: t('languages.spanish'),
        swedish: t('languages.swedish'),
        thai: t('languages.thai'),
        turkish: t('languages.turkish'),
        ukrainian: t('languages.ukrainian'),
        vietnamese: t('languages.vietnamese'),
    };

    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://youglish.com/public/emb/widget.js";
        script.async = true;
        document.body.appendChild(script);
    }, []);

    // Save preferences to localStorage when they change
    useEffect(() => {
        localStorage.setItem('translateFrom', translateFrom);
    }, [translateFrom]);

    useEffect(() => {
        localStorage.setItem('translateTo', translateTo);
    }, [translateTo]);

    useEffect(() => {
        localStorage.setItem('youglishLanguage', youglishLanguage);
    }, [youglishLanguage]);

    useEffect(() => {
        localStorage.setItem('savingEnabled', savingEnabled);
    }, [savingEnabled]);

    useEffect(() => {
        const fetchCollections = async () => {
            try {
                const response = await axiosInstance.get('/api/collections');
                if (response.status === 200) {
                    console.log(response.data)
                    setCollections(response.data);
                    if (!localStorage.getItem('selectedCollection') && response.data.length > 0) {
                        setSelectedCollection(response.data[0].id); // Default to the first collection
                    }
                }
            } catch (error) {
                console.error("Error fetching collections", error);
            }
        };
        fetchCollections();
    }, []);

    useEffect(() => {
        localStorage.setItem('selectedCollection', selectedCollection);
    }, [selectedCollection]);

    const translateWord = async () => {
        setLoading(true);
        try {
            const response = await axiosInstance.post('/api/translations', {
                lexicalItem: wordInput,
                translateFrom: translateFrom,
                translateTo: translateTo,
                savingEnabled: savingEnabled,
                collectionID: savingEnabled ? parseInt(selectedCollection, 10) : 0
            });
            if (response.status === 200) {
                setTranslation(response.data);

                // Initialize or update the YouGlish widget after translation
                if (window.YG) {
                    const widget = new window.YG.Widget('widget-1', {
                        autoStart: 0,
                        width: 640,
                        lang: youglishLanguage, // Use the selected Youglish language
                        components: 92, // search box & caption
                        events: {
                            onFetchDone: onFetchDone,
                            onVideoChange: onVideoChange,
                            onCaptionConsumed: onCaptionConsumed,
                        },
                    });

                    // Fetch the correct word/phrase based on the selected language
                    const phraseToFetch = youglishLanguage === translateTo
                        ? response.data.translatedLexicalItem
                        : wordInput;

                    widget.fetch(phraseToFetch);
                }
            } else {
                setTranslation({ error: t('main.errorTranslationRetry') });
            }
        } catch (error) {
                setTranslation({ error: t('main.errorUnableTotranslate') });
        } finally {
            setLoading(false);
        }
    };

    function onFetchDone(event) {
    }

    function onVideoChange(event) {
    }

    function onCaptionConsumed(event) {
    }

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const swapLanguages = () => {
        const temp = translateFrom;
        setTranslateFrom(translateTo);
        setTranslateTo(temp);
    };

    return (
        <>
            <div className="mx-auto max-w-2xl text-center px-4">
                <h1 className="text-5xl font-extrabold tracking-tight text-gray-900 sm:text-6xl lg:text-7xl">
                    Lexi Hero <span role="img" aria-label="hero">🦸</span>
                </h1>
                <p className="mt-4 text-lg leading-6 text-gray-600 sm:text-xl lg:text-2xl">
                    {t('welcome.description')}
                </p>
            </div>
            <div className="mx-auto mt-16 max-w-xl sm:mt-20">
                <div className="flex items-center justify-between gap-2">
                    <select
                        value={translateFrom}
                        onChange={(e) => setTranslateFrom(e.target.value)}
                        className="flex-grow block rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    >
                        {Object.keys(languageOptions).map(lang => (
                            <option key={lang} value={lang}>
                                {languageOptions[lang]}
                            </option>
                        ))}
                    </select>
                    <button
                        onClick={swapLanguages}
                        className="bg-gray hover:bg-gray-300 py-2 px-3 rounded focus:outline-none"
                    >
                        🔄
                    </button>
                    <select
                        value={translateTo}
                        onChange={(e) => setTranslateTo(e.target.value)}
                        className="flex-grow block rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    >
                        {Object.keys(languageOptions).map(lang => (
                            <option key={lang} value={lang}>
                                {languageOptions[lang]}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="mt-4">
                    <label className="block text-sm font-medium text-gray-700">{t('main.hearIt')}</label>
                    <select
                        value={youglishLanguage}
                        onChange={(e) => setYouglishLanguage(e.target.value)}
                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    >
                        {Object.keys(languageOptions)
                            .filter(lang => lang === translateFrom || lang === translateTo)
                            .map(lang => (
                                <option key={lang} value={lang}>
                                    {languageOptions[lang]}
                                </option>
                            ))}
                    </select>
                </div>
                <div className="sm:col-span-2 mt-6">
                    <div className="mt-2.5">
                        <input
                            type="text"
                            value={wordInput}
                            onChange={(e) => setWordInput(e.target.value)}
                            placeholder={t('main.typeWordOrPhrase')}
                            className="block w-full rounded-lg border-indigo-500 px-4 py-3 text-gray-900 shadow-lg ring-1 ring-indigo-300 placeholder:text-gray-400 focus:ring-4 focus:ring-indigo-600 sm:text-base sm:leading-6 transition-transform duration-200 ease-in-out hover:scale-105"
                        />
                    </div>
                </div>
                <div className="mt-4 flex items-center">
                    <input
                        type="checkbox"
                        checked={savingEnabled}
                        onChange={() => setSavingEnabled(!savingEnabled)}
                        className="h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                    />
                    <label className="ml-2 text-sm text-gray-700">{t('main.saveTranslationAutomatically')}</label>
                </div>
                {savingEnabled && (
                    <div className="mt-4">
                        <label className="block text-sm font-medium text-gray-700">{t('main.selectCollection')}</label>
                        <select
                            value={selectedCollection}
                            onChange={(e) => setSelectedCollection(Number(e.target.value))}
                            className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        >
                            {!!collections && collections.length > 0 ? (
                                collections.map((collection) => (
                                    <option key={collection.id} value={collection.id}>
                                        {collection.name}
                                    </option>
                                ))
                            ) : (
                                <option value={0}>Default Collection</option>
                            )}
                        </select>
                    </div>
                )}
                <div className="mt-10">
                    <button
                        onClick={translateWord}
                        disabled={loading}
                        className="block w-full rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                        {loading ? t('main.translating') + '⏳' : t('main.translate') + ' ✨'}
                    </button>
                </div>

                <div>
                    {translation && (
                        <div
                            className="mx-auto max-w-2xl block m-6 p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
                            <div className="">
                                {translation.error ? (
                                    <p className="text-lg text-red-600">
                                        {t('main.error', { error: translation.error })}
                                    </p>
                                ) : (
                                    <div>
                                        <div className="space-y-4">
                                            <p className="text-gray-700">
                                                <strong>{t('main.lexicalItem')}: </strong>
                                                <span>{translation.originalLexicalItem}</span>
                                            </p>
                                            <p className="text-gray-700">
                                                <strong>{t('main.meaning')}: </strong>
                                                <span>{translation.originalMeaning}</span>
                                            </p>
                                            <p className="text-gray-700">
                                                <strong>{t('main.examples')}: </strong>
                                            </p>
                                            <ul className="list-disc ml-5">
                                                {translation.originalExamples.map((example, index) => (
                                                    <li key={index}>{example}</li>
                                                ))}
                                            </ul>

                                            <hr className="border-t border-gray-300 my-4" />

                                            <p className="text-gray-700">
                                                <strong>{t('main.translationTo', { language: capitalizeFirstLetter(translation.translatedTo) })}: </strong>
                                                <span>{translation.translatedLexicalItem}</span>
                                            </p>
                                            <p className="text-gray-700">
                                                <strong>{t('main.translatedMeaning')}: </strong>
                                                <span>{translation.translatedMeaning}</span>
                                            </p>
                                            <p className="text-gray-700">
                                                <strong>{t('main.translatedExamples')}: </strong>
                                            </p>
                                            <ul className="list-disc ml-5">
                                                {translation.translatedExamples.map((example, index) => (
                                                    <li key={index}>{example}</li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>

                    )}
                    <div>
                        <div id="widget-1" className="mt-10"></div>
                    </div>
                </div>
            </div >
        </>
    );
}
