import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpBackend from 'i18next-http-backend';

const resources = {
    en: {
        translation: {
            welcome: {
                title: "Welcome to our application",
                lexihHero: "Lexi Hero",
                description: "Your Ultimate Vocabulary Mastery Tool",
            },
            auth: {
                signUpTitle: "Sign up for an account",
                signInTitle: "Sign in to your account",
                username: "Username",
                password: "Password",
                incorrectCredentials: "Incorrect username or password",
                userExists: "This user already exists",
                signUpButton: "Sign up",
                signInButton: "Sign in",
                toggleTextSignIn: "Already have an account?",
                toggleLinkSignIn: "Sign in here",
                toggleTextSignUp: "Not a member?",
                toggleLinkSignUp: "Sign up here",
                agreementText: "I agree to the",
                privacyPolicy: "Privacy Policy",
                termsOfUse: "Terms of Use",
                and: "and",
            },
            header: {
                savedTranslations: "Saved 💾",
                userSettings: "User Settings ⚙️",
                logout: "Logout",
            },
            footer: {
                privacyPolicy: "Privacy Policy",
                tos: "Terms of Service",
                contact: "Contact"
            },
            main: {
                hearIt: "Hear it in",
                typeWordOrPhrase: "Type a word or phrase here",
                saveTranslationAutomatically: "Save translation automatically",
                selectCollection: "Choose a collection",
                translating: "Translating",
                translate: "Translate",
                error: "Error: {{error}}",
                lexicalItem: "Lexical Item",
                meaning: "Meaning",
                examples: "Examples",
                translationTo: "Translation to {{language}}",
                translatedMeaning: "Translated Meaning",
                translatedExamples: "Translated Examples",
                errorUnableTotranslate: "Oops! We couldn't translate this.",
                errorTranslationRetry: "Oops! We couldn't translate this. Please try again later.",
            },
            notFound: {
                title: "404",
                message: "Something's missing.",
                description: "Sorry, we can't find that page. You'll find lots to explore on the home page.",
                backToHomepage: "Back to Homepage"
            },
            languages: {
                arabic: 'Arabic',
                chinese: 'Chinese',
                dutch: 'Dutch',
                english: 'English',
                french: 'French',
                german: 'German',
                greek: 'Greek',
                hebrew: 'Hebrew',
                italian: 'Italian',
                japanese: 'Japanese',
                korean: 'Korean',
                polish: 'Polish',
                portuguese: 'Portuguese',
                russian: 'Russian',
                spanish: 'Spanish',
                swedish: 'Swedish',
                thai: 'Thai',
                turkish: 'Turkish',
                ukrainian: 'Ukrainian',
                vietnamese: 'Vietnamese',
            },
            collection: {
                translations: "Translations",
                collections: "Collections",
                newCollectionName: "New collection name",
                create: "Create",
                noCollectionsFound: "No collections found",
                backToCollections: "Back to Collections",
                exportForQuizlet: "Export for Quizlet",
                noTranslationsFound: "No translations found",
                delete: "Delete",
                originalMeaning: "Original Meaning",
                originalExamples: "Original Examples",
                translatedMeaning: "Translated Meaning",
                translatedExamples: "Translated Examples",
                from: "From",
                search: "Search",
                addTranslation: "Add Translation",
                editTranslation: "Edit Translation",
                translationSaved: "Translation saved successfully",
                translationDeleted: "Translation deleted successfully",
                collectionCreated: "Collection created successfully",
                collectionDeleted: "Collection deleted successfully",
                loading: "Loading...",
                errorFetchingCollections: "Error fetching collections",
                errorFetchingTranslations: "Error fetching translations",
                confirmDeleteTranslation: "Are you sure you want to delete this translation?",
                confirmDeleteCollection: "Are you sure you want to delete this collection?"
            },
            userSettings: {
                title: "User Settings",
                description: "Manage your account settings. You can delete your account.",
                confirmDeletionTitle: "Confirm Account Deletion",
                confirmDeletionMessage: "Are you sure you want to delete your account? This action cannot be undone.",
                deleteButton: "Delete Account",
                cancelButton: "Cancel",
                confirmDeleteButton: "Yes, delete my account"
            },
        },
    },
    es: {
        translation: {
            welcome: {
                title: "Bienvenido a nuestra aplicación",
                lexihHero: "Lexi Hero",
                description: "Tu herramienta definitiva para dominar el vocabulario",
            },
            auth: {
                signUpTitle: "Regístrate para obtener una cuenta",
                signInTitle: "Inicia sesión en tu cuenta",
                username: "Nombre de usuario",
                password: "Contraseña",
                incorrectCredentials: "Nombre de usuario o contraseña incorrectos",
                userExists: "Este usuario ya existe",
                signUpButton: "Regístrate",
                signInButton: "Iniciar sesión",
                toggleTextSignIn: "¿Ya tienes una cuenta?",
                toggleLinkSignIn: "Inicia sesión aquí",
                toggleTextSignUp: "¿No eres miembro?",
                toggleLinkSignUp: "Regístrate aquí",
                agreementText: "Acepto la",
                privacyPolicy: "Política de privacidad",
                termsOfUse: "Términos de uso",
                and: "y",
            },
            header: {
                savedTranslations: "Guardado 💾",
                userSettings: "Configuración del usuario ⚙️",
                logout: "Cerrar sesión",
            },
            footer: {
                privacyPolicy: "Política de privacidad",
                tos: "Términos del servicio",
                contact: "Contacto"
            },
            main: {
                hearIt: "Escúchalo en",
                typeWordOrPhrase: "Escribe una palabra o frase aquí",
                saveTranslationAutomatically: "Guardar traducción automáticamente",
                selectCollection: "Elegir una colección",
                translating: "Traduciendo",
                translate: "Traducir",
                error: "Error: {{error}}",
                lexicalItem: "Elemento léxico",
                meaning: "Significado",
                examples: "Ejemplos",
                translationTo: "Traducción a {{language}}",
                translatedMeaning: "Significado traducido",
                translatedExamples: "Ejemplos traducidos",
                errorUnableTotranslate: "¡Vaya! No pudimos traducir esto.",
                errorTranslationRetry: "¡Vaya! No pudimos traducir esto. Por favor, inténtalo de nuevo más tarde.",
            },
            notFound: {
                title: "404",
                message: "Algo falta.",
                description: "Lo sentimos, no podemos encontrar esa página. Encontrarás muchas cosas para explorar en la página de inicio.",
                backToHomepage: "Volver a la página de inicio"
            },
            languages: {
                arabic: 'Árabe',
                chinese: 'Chino',
                dutch: 'Neerlandés',
                english: 'Inglés',
                french: 'Francés',
                german: 'Alemán',
                greek: 'Griego',
                hebrew: 'Hebreo',
                italian: 'Italiano',
                japanese: 'Japonés',
                korean: 'Coreano',
                polish: 'Polaco',
                portuguese: 'Portugués',
                russian: 'Ruso',
                spanish: 'Español',
                swedish: 'Sueco',
                thai: 'Tailandés',
                turkish: 'Turco',
                ukrainian: 'Ucraniano',
                vietnamese: 'Vietnamita',
            },
            collection: {
                translations: "Traducciones",
                collections: "Colecciones",
                newCollectionName: "Nombre de nueva colección",
                create: "Crear",
                noCollectionsFound: "No se encontraron colecciones",
                backToCollections: "Volver a colecciones",
                exportForQuizlet: "Exportar para Quizlet",
                noTranslationsFound: "No se encontraron traducciones",
                delete: "Eliminar",
                originalMeaning: "Significado original",
                originalExamples: "Ejemplos originales",
                translatedMeaning: "Significado traducido",
                translatedExamples: "Ejemplos traducidos",
                from: "De",
                search: "Buscar",
                addTranslation: "Agregar traducción",
                editTranslation: "Editar traducción",
                translationSaved: "Traducción guardada exitosamente",
                translationDeleted: "Traducción eliminada exitosamente",
                collectionCreated: "Colección creada exitosamente",
                collectionDeleted: "Colección eliminada exitosamente",
                loading: "Cargando...",
                errorFetchingCollections: "Error al obtener colecciones",
                errorFetchingTranslations: "Error al obtener traducciones",
                confirmDeleteTranslation: "¿Estás seguro de que deseas eliminar esta traducción?",
                confirmDeleteCollection: "¿Estás seguro de que deseas eliminar esta colección?"
            }
        },
    },
    fr: {
        translation: {
            welcome: {
                title: "Bienvenue dans notre application",
                lexihHero: "Lexi Hero",
                description: "Votre outil ultime de maîtrise du vocabulaire",
            },
            auth: {
                signUpTitle: "Inscrivez-vous pour un compte",
                signInTitle: "Connectez-vous à votre compte",
                username: "Nom d'utilisateur",
                password: "Mot de passe",
                incorrectCredentials: "Nom d'utilisateur ou mot de passe incorrect",
                userExists: "Cet utilisateur existe déjà",
                signUpButton: "S'inscrire",
                signInButton: "Se connecter",
                toggleTextSignIn: "Vous avez déjà un compte ?",
                toggleLinkSignIn: "Connectez-vous ici",
                toggleTextSignUp: "Pas encore membre ?",
                toggleLinkSignUp: "Inscrivez-vous ici",
                agreementText: "J'accepte les",
                privacyPolicy: "Politique de confidentialité",
                termsOfUse: "Conditions d'utilisation",
                and: "et",
            },
            header: {
                savedTranslations: "Sauvegardé 💾",
                userSettings: "Paramètres utilisateur ⚙️",
                logout: "Se déconnecter",
            },
            footer: {
                privacyPolicy: "Politique de confidentialité",
                tos: "Conditions de service",
                contact: "Contact"
            },
            main: {
                hearIt: "Écoutez-le en",
                typeWordOrPhrase: "Tapez un mot ou une phrase ici",
                saveTranslationAutomatically: "Enregistrer la traduction automatiquement",
                selectCollection: "Choisissez une collection",
                translating: "Traduction en cours",
                translate: "Traduire",
                error: "Erreur : {{error}}",
                lexicalItem: "Élément lexical",
                meaning: "Signification",
                examples: "Exemples",
                translationTo: "Traduction en {{language}}",
                translatedMeaning: "Signification traduite",
                translatedExamples: "Exemples traduits",
                errorUnableTotranslate: "Oups ! Nous n'avons pas pu traduire cela.",
                errorTranslationRetry: "Oups ! Nous n'avons pas pu traduire cela. Veuillez réessayer plus tard.",
            },
            notFound: {
                title: "404",
                message: "Il manque quelque chose.",
                description: "Désolé, nous ne pouvons pas trouver cette page. Vous trouverez beaucoup à explorer sur la page d'accueil.",
                backToHomepage: "Retour à la page d'accueil"
            },
            languages: {
                arabic: 'Arabe',
                chinese: 'Chinois',
                dutch: 'Néerlandais',
                english: 'Anglais',
                french: 'Français',
                german: 'Allemand',
                greek: 'Grec',
                hebrew: 'Hébreu',
                italian: 'Italien',
                japanese: 'Japonais',
                korean: 'Coréen',
                polish: 'Polonais',
                portuguese: 'Portugais',
                russian: 'Russe',
                spanish: 'Espagnol',
                swedish: 'Suédois',
                thai: 'Thaï',
                turkish: 'Turc',
                ukrainian: 'Ukrainien',
                vietnamese: 'Vietnamien',
            },
            collection: {
                translations: "Traductions",
                collections: "Collections",
                newCollectionName: "Nom de la nouvelle collection",
                create: "Créer",
                noCollectionsFound: "Aucune collection trouvée",
                backToCollections: "Retour aux collections",
                exportForQuizlet: "Exporter pour Quizlet",
                noTranslationsFound: "Aucune traduction trouvée",
                delete: "Supprimer",
                originalMeaning: "Signification originale",
                originalExamples: "Exemples originaux",
                translatedMeaning: "Signification traduite",
                translatedExamples: "Exemples traduits",
                from: "De",
                search: "Recherche",
                addTranslation: "Ajouter une traduction",
                editTranslation: "Modifier la traduction",
                translationSaved: "Traduction enregistrée avec succès",
                translationDeleted: "Traduction supprimée avec succès",
                collectionCreated: "Collection créée avec succès",
                collectionDeleted: "Collection supprimée avec succès",
                loading: "Chargement...",
                errorFetchingCollections: "Erreur lors de la récupération des collections",
                errorFetchingTranslations: "Erreur lors de la récupération des traductions",
                confirmDeleteTranslation: "Êtes-vous sûr de vouloir supprimer cette traduction ?",
                confirmDeleteCollection: "Êtes-vous sûr de vouloir supprimer cette collection ?"
            }
        },
    },
    ru: {
        translation: {
            welcome: {
                title: "Добро пожаловать в наше приложение",
                lexihHero: "Lexi Hero",
                description: "Ваш идеальный инструмент для улучшения словарного запаса",
            },
            auth: {
                signUpTitle: "Зарегистрируйтесь для создания аккаунта",
                signInTitle: "Войдите в свой аккаунт",
                username: "Имя пользователя",
                password: "Пароль",
                incorrectCredentials: "Неверное имя пользователя или пароль",
                userExists: "Этот пользователь уже существует",
                signUpButton: "Зарегистрироваться",
                signInButton: "Войти",
                toggleTextSignIn: "Уже есть аккаунт?",
                toggleLinkSignIn: "Войдите здесь",
                toggleTextSignUp: "Не зарегистрирован?",
                toggleLinkSignUp: "Зарегистрируйтесь здесь",
                agreementText: "Я согласен с",
                privacyPolicy: "Политика конфиденциальности",
                termsOfUse: "Условия использования",
                and: "и",
            },
            header: {
                savedTranslations: "Сохраненные 💾",
                userSettings: "Настройки Пользователя ⚙️",
                logout: "Выйти",
            },
            footer: {
                privacyPolicy: "Политика конфиденциальност",
                tos: "Условия использования",
                contact: "Контакт"
            },
            main: {
                hearIt: "Язык для прослушивания",
                typeWordOrPhrase: "Напиши словo или фразу",
                saveTranslationAutomatically: "Сохранить перевод автоматически",
                selectCollection: "Выбери коллекцию",
                translating: "Перевожу",
                translate: "Перевести",
                error: "Ошибка: {{error}}",
                lexicalItem: "Лексическая единица",
                meaning: "Значение",
                examples: "Примеры",
                translationTo: "Перевод на {{language}}",
                translatedMeaning: "Переведенное значение",
                translatedExamples: "Переведенные примеры",
                errorUnableTotranslate: "Упс! Мы не смогли выполнить перевод.",
                errorTranslationRetry: "Упс! Мы не смогли выполнить перевод. Пожалуйста, попробуйте позже.",
            },
            userSettings: {
                title: "Настройки пользователя",
                description: "Управляйте настройками своей учетной записи. Вы можете удалить свою учетную запись.",
                confirmDeletionTitle: "Подтвердите удаление учетной записи",
                confirmDeletionMessage: "Вы уверены, что хотите удалить свою учетную запись? Это действие нельзя отменить.",
                deleteButton: "Удалить учетную запись",
                cancelButton: "Отмена",
                confirmDeleteButton: "Да, удалить мою учетную запись"
            },
            notFound: {
                title: "404",
                message: "Что-то пропало.",
                description: "Извините, мы не можем найти эту страницу. На главной странице вы найдете много интересного.",
                backToHomepage: "Назад на главную"
            },
            languages: {
                arabic: 'Арабский',
                chinese: 'Китайский',
                dutch: 'Нидерландский',
                english: 'Английский',
                french: 'Французский',
                german: 'Немецкий',
                greek: 'Греческий',
                hebrew: 'Иврит',
                italian: 'Итальянский',
                japanese: 'Японский',
                korean: 'Корейский',
                polish: 'Польский',
                portuguese: 'Португальский',
                russian: 'Русский',
                spanish: 'Испанский',
                swedish: 'Шведский',
                thai: 'Тайский',
                turkish: 'Турецкий',
                ukrainian: 'Украинский',
                vietnamese: 'Вьетнамский',
            },
            collection: {
                translations: "Переводы",
                collections: "Коллекции",
                newCollectionName: "Новое имя коллекции",
                create: "Создать",
                noCollectionsFound: "Коллекции не найдены",
                backToCollections: "Назад к коллекциям",
                exportForQuizlet: "Экспорт для Quizlet",
                noTranslationsFound: "Переводы не найдены",
                delete: "Удалить",
                originalMeaning: "Оригинальное значение",
                originalExamples: "Оригинальные примеры",
                translatedMeaning: "Переведенное значение",
                translatedExamples: "Переведенные примеры",
                from: "От",
                search: "Поиск",
                addTranslation: "Добавить перевод",
                editTranslation: "Редактировать перевод",
                translationSaved: "Перевод успешно сохранен",
                translationDeleted: "Перевод успешно удален",
                collectionCreated: "Коллекция успешно создана",
                collectionDeleted: "Коллекция успешно удалена",
                loading: "Загрузка...",
                errorFetchingCollections: "Ошибка при получении коллекций",
                errorFetchingTranslations: "Ошибка при получении переводов",
                confirmDeleteTranslation: "Вы уверены, что хотите удалить этот перевод?",
                confirmDeleteCollection: "Вы уверены, что хотите удалить эту коллекцию?"
            }
        },
    },
};

i18n
    .use(HttpBackend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng: "en", // default language
        interpolation: {
            escapeValue: false, // React already does escaping
        },
    });

export default i18n;
