import { useState } from 'react';
import {
    Dialog,
    DialogPanel,
    Disclosure,
    DisclosurePanel,
    Popover,
    PopoverGroup,
    PopoverPanel,
} from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import UserSettings from './user_settings';
import axiosInstance from "./axiosInstance";
import { useTranslation } from 'react-i18next'; // Import the useTranslation hook
import LanguageSwitcher from './language_switcher';

export default function Header() {
    const { t } = useTranslation(); // Initialize the translation hook
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [settingsOpen, setSettingsOpen] = useState(false);
    const [deletionMessage, setDeletionMessage] = useState(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleGoToSavedTranslations = () => {
        navigate('/saved-translations');
    };

    const handleLogout = () => {
        Cookies.remove('refresh_token');
        Cookies.remove('access_token');
        navigate('/auth');
    };

    const handleDeleteAccount = async () => {
        setLoading(true);
        try {
            const response = await axiosInstance.delete('/api/accounts');
            if (response.status === 200) {
                setDeletionMessage(t('deletionSuccess')); // Use translation key
                navigate('/auth');
            } else {
                setDeletionMessage(t('deletionFailure')); // Use translation key
            }
        } catch (error) {
            setDeletionMessage(t('deletionError')); // Use translation key
        } finally {
            setLoading(false);
            Cookies.remove('refresh_token');
            Cookies.remove('access_token');
        }
    };

    return (
        <header className="bg-white">
            <nav aria-label="Global" className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8">
                <div className="flex lg:flex-1">
                    <a href="/" className="-m-1.5 p-1.5">
                        <span className="sr-only">{t('lexiHero')}</span> {/* Use translation key */}
                        <img alt="" src='https://lexihero.com/icon.svg' className="h-10 w-auto" />
                    </a>
                </div>
                <div className="flex lg:hidden">
                    <button
                        type="button"
                        onClick={() => setMobileMenuOpen(true)}
                        className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                    >
                        <span className="sr-only">{t('openMainMenu')}</span> {/* Use translation key */}
                        <Bars3Icon aria-hidden="true" className="h-6 w-6" />
                    </button>
                </div>
                <PopoverGroup className="hidden lg:flex lg:gap-x-12">
                    <Popover className="relative">
                        <PopoverPanel
                            transition
                            className="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5 transition data-[closed]:translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in"
                        >
                            <div className="p-4"></div>
                            <div className="grid grid-cols-2 divide-x divide-gray-900/5 bg-gray-50"></div>
                        </PopoverPanel>
                    </Popover>
                </PopoverGroup>
                <div className="hidden lg:flex lg:flex-1 lg:justify-end space-x-4">
                    <LanguageSwitcher /> {/* Add the LanguageSwitcher here */}
                    <button
                        onClick={handleGoToSavedTranslations}
                        className="text-sm font-semibold leading-6 text-gray-900"
                    >
                        {t('header.savedTranslations')} {/* Use translation key */}
                    </button>
                    <button
                        onClick={() => setSettingsOpen(true)}
                        className="text-sm font-semibold leading-6 text-gray-900"
                    >
                        {t('header.userSettings')} {/* Use translation key */}
                    </button>
                    <button onClick={handleLogout} className="text-sm font-semibold leading-6 text-gray-900">
                        {t('header.logout')} <span aria-hidden="true">&rarr;</span> {/* Use translation key */}
                    </button>
                </div>
            </nav>

            {/* Mobile Menu */}
            <Dialog open={mobileMenuOpen} onClose={() => setMobileMenuOpen(false)} className="lg:hidden">
                <div className="fixed inset-0 z-10" />
                <DialogPanel
                    className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                    <div className="flex items-center justify-between">
                        <a href="#" className="-m-1.5 p-1.5">
                            <span className="sr-only">{t('lexiHero')}</span> {/* Use translation key */}
                            <img alt="" src='https://lexihero.com/icon.svg' className="h-10 w-auto" />
                        </a>
                        <button
                            type="button"
                            onClick={() => setMobileMenuOpen(false)}
                            className="-m-2.5 rounded-md p-2.5 text-gray-700"
                        >
                            <span className="sr-only">{t('closeMenu')}</span> {/* Use translation key */}
                            <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                        </button>
                    </div>
                    <div className="mt-6 flow-root">
                        <div className="-my-6 divide-y divide-gray-500/10">
                            <div className="space-y-2 py-6">
                                <Disclosure as="div" className="-mx-3">
                                    <DisclosurePanel className="mt-2 space-y-2">
                                        {/* Add mobile-specific navigation items here */}
                                    </DisclosurePanel>
                                </Disclosure>
                            </div>
                            <div className="py-6">
                                <LanguageSwitcher /> {/* Add the LanguageSwitcher here */}
                                <button
                                    onClick={handleGoToSavedTranslations}
                                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                >
                                    {t('header.savedTranslations')} {/* Use translation key */}
                                </button>
                                <button
                                    onClick={() => setSettingsOpen(true)}
                                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                >
                                    {t('header.userSettings')} {/* Use translation key */}
                                </button>
                                <button
                                    onClick={handleLogout}
                                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                >
                                    {t('header.logout')} <span aria-hidden="true">&rarr;</span> {/* Use translation key */}
                                </button>
                            </div>
                        </div>
                    </div>
                </DialogPanel>
            </Dialog>

            {/* User Settings Component */}
            <UserSettings
                isOpen={settingsOpen}
                onClose={() => setSettingsOpen(false)}
                onDeleteAccount={handleDeleteAccount}
                deletionMessage={deletionMessage} // Pass deletion message to UserSettings if needed
                loading={loading} // Pass loading state to UserSettings if needed
            />
        </header>
    );
}
