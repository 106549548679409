import Header from "./header";
import Footer from "./footer";
import React, { useState, useEffect } from "react";
import axiosInstance from "./axiosInstance";
import { useTranslation } from 'react-i18next';

export default function SavedTranslations() {
    const { t } = useTranslation();
    const [collections, setCollections] = useState([]);
    const [selectedCollection, setSelectedCollection] = useState(null);
    const [translations, setTranslations] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [newCollectionName, setNewCollectionName] = useState("");

    useEffect(() => {
        const fetchCollections = async () => {
            try {
                const response = await axiosInstance.get("/api/collections");
                setCollections(response.data);
            } catch (error) {
                console.error("Error fetching collections:", error);
            }
        };
        fetchCollections();
    }, []);

    const fetchTranslations = async (collectionId) => {
        setIsLoading(true);
        try {
            const response = await axiosInstance.get(`/api/collections/${collectionId}/translations`);
            setTranslations(response.data);
            setSelectedCollection(collectionId);
        } catch (error) {
            console.error("Error fetching translations:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleBack = () => {
        setSelectedCollection(null);
        setTranslations([]);
    };

    const downloadCSV = async () => {
        if (!selectedCollection) return;

        try {
            const response = await axiosInstance.get(
                `/api/collections/${selectedCollection}/export?product=quizlet`,
                { responseType: "blob" }
            );

            const blob = new Blob([response.data], { type: "text/plain" });
            const url = window.URL.createObjectURL(blob);

            const link = document.createElement("a");
            link.href = url;
            link.download = `collection_${selectedCollection}_quizlet.txt`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error("Error downloading CSV:", error);
        }
    };

    const deleteTranslation = async (translationId) => {
        try {
            await axiosInstance.delete(
                `/api/collections/${selectedCollection}/translations`,
                { params: { translationIds: translationId } }
            );
            setTranslations((prev) => prev.filter((item) => item.translation.id !== translationId));
        } catch (error) {
            console.error("Error deleting translation:", error);
        }
    };

    const createCollection = async () => {
        if (!newCollectionName.trim()) return;

        try {
            const response = await axiosInstance.post("/api/collections", {
                collectionName: newCollectionName,
            });
            setCollections((prev) => [...prev, { id: response.data.id, name: newCollectionName }]);
            setNewCollectionName("");
        } catch (error) {
            console.error("Error creating collection:", error);
        }
    };

    const deleteCollection = async (collectionId) => {
        try {
            await axiosInstance.delete(`/api/collections/${collectionId}`);
            setCollections((prev) => prev.filter((collection) => collection.id !== collectionId));
            if (selectedCollection === collectionId) {
                handleBack();
            }
        } catch (error) {
            console.error("Error deleting collection:", error);
        }
    };

    return (
        <>
            <div className="isolate bg-gray-50 min-h-screen px-6 lg:px-8">
                <Header />
                <div className="py-12">
                    <h2 className="text-4xl font-extrabold text-center text-gray-800 mb-8">
                        {selectedCollection ? t("collection.translations") : t("collection.collections")}
                    </h2>

                    <div className="flex justify-center">
                        {!selectedCollection ? (
                            <div className="w-full max-w-7xl space-y-6">
                                <div className="flex items-center gap-4">
                                    <input
                                        type="text"
                                        className="flex-grow p-2 border border-gray-300 rounded"
                                        placeholder={t("collection.newCollectionName")}
                                        value={newCollectionName}
                                        onChange={(e) => setNewCollectionName(e.target.value)}
                                    />
                                    <button
                                        className="px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700"
                                        onClick={createCollection}
                                    >
                                        {t("collection.create")}
                                    </button>
                                </div>
                                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
                                    {!!collections && collections.length > 0 ? (
                                        collections.map((collection) => (
                                            <div
                                                key={collection.id}
                                                className="bg-white rounded-lg shadow-lg p-6 hover:shadow-xl transform hover:scale-105 transition duration-300 border border-gray-100"
                                                onClick={() => fetchTranslations(collection.id)}
                                            >
                                                <h3 className="text-xl font-semibold text-gray-800 cursor-pointer">
                                                    {collection.name}
                                                </h3>
                                                <button
                                                    className="mt-4 px-3 py-1 bg-red-600 text-white rounded hover:bg-red-700"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        deleteCollection(collection.id);
                                                    }}
                                                >
                                                    {t("collection.delete")}
                                                </button>
                                            </div>
                                        ))
                                    ) : (
                                        <p className="text-center text-gray-500">
                                            {t("collection.noCollectionsFound")}
                                        </p>
                                    )}
                                </div>
                            </div>
                        ) : (
                            <div className="w-full max-w-4xl">
                                <button
                                    className="mb-6 mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 sm:mt-0 sm:w-auto sm:text-sm"
                                    onClick={handleBack}
                                >
                                    {t("collection.backToCollections")}
                                </button>
                                {isLoading ? (
                                    <div className="space-y-4">
                                        {Array.from({ length: 3 }).map((_, idx) => (
                                            <div
                                                key={idx}
                                                className="h-24 bg-gray-200 rounded-md animate-pulse"
                                            ></div>
                                        ))}
                                    </div>
                                ) : (
                                    <div className="space-y-4">
                                        <button
                                            className="mb-4 inline-flex w-full justify-center rounded-md border border-gray-300 bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 sm:mt-0 sm:w-auto sm:text-sm"
                                            onClick={downloadCSV}
                                        >
                                            {t("collection.exportForQuizlet")}
                                        </button>
                                        {!!translations && translations.length > 0 ? (
                                            translations.map((item) => (
                                                <div
                                                    key={item.id}
                                                    className="p-6 bg-white rounded-lg shadow-lg border border-gray-100 flex justify-between items-center"
                                                >
                                                    <div>
                                                        <h4 className="text-lg font-semibold text-gray-800">
                                                            {item.translation.originalLexicalItem} →{" "}
                                                            {item.translation.translatedLexicalItem}
                                                        </h4>
                                                        <p className="text-sm text-gray-600 mt-2">
                                                            <strong>{t("collection.from")}:</strong>{" "}
                                                            {item.translation.translatedFrom}
                                                            {" → "}
                                                            {item.translation.translatedTo}
                                                        </p>
                                                        <div className="mt-2 text-gray-700 space-y-2">
                                                            <p>
                                                                <strong>{t("collection.originalMeaning")}:</strong>{" "}
                                                                {item.translation.originalMeaning}
                                                            </p>
                                                            <p>
                                                                <strong>{t("collection.originalExamples")}:</strong>{" "}
                                                                {item.translation.originalExamples.join(". ")}
                                                            </p>
                                                            <p>
                                                                <strong>{t("collection.translatedMeaning")}:</strong>{" "}
                                                                {item.translation.translatedMeaning}
                                                            </p>
                                                            <p>
                                                                <strong>{t("collection.translatedExamples")}:</strong>{" "}
                                                                {item.translation.translatedExamples.join(". ")}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <button
                                                        className="ml-4 px-3 py-1 bg-red-600 text-white rounded hover:bg-red-700"
                                                        onClick={() => deleteTranslation(item.translation.id)}
                                                    >
                                                        {t("collection.delete")}
                                                    </button>
                                                </div>
                                            ))
                                        ) : (
                                            <p className="text-center text-gray-500">
                                                {t("collection.noTranslationsFound")}
                                            </p>
                                        )}
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
}
