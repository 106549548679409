import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function LanguageSwitcher() {
    const { i18n } = useTranslation();
    const [currentLanguage, setCurrentLanguage] = useState('en'); // Default to language code

    // Effect to get the stored language or default to English
    useEffect(() => {
        const storedLanguage = localStorage.getItem('language');
        if (storedLanguage) {
            i18n.changeLanguage(storedLanguage);
            setCurrentLanguage(storedLanguage);
        } else {
            localStorage.setItem('language', 'en'); // Set default language
            setCurrentLanguage('en'); // Default to language code
        }
    }, [i18n]);

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        localStorage.setItem('language', lng); // Save to local storage
        setCurrentLanguage(lng); // Update state with language code
    };

    const getLanguageFullName = (lng) => {
        switch (lng) {
            case 'en': return 'English';
            case 'ru': return 'Russian';
            case 'es': return 'Spanish';
            case 'fr': return 'French';
            default: return 'English'; // Fallback to English
        }
    };

    return (
        <>
            <select
                value={currentLanguage}
                onChange={(e) => changeLanguage(e.target.value)}
                className="block w-40 rounded-md border border-gray-300 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            >
                {['en', 'es', 'fr', 'ru'].map((lng) => (
                    <option key={lng} value={lng}>
                        {getLanguageFullName(lng)} {/* Show full language name */}
                    </option>
                ))}
            </select>
        </>
    );
}
