import React from 'react';
import { useNavigate } from 'react-router-dom';

function TermsOfUse() {
    const navigate = useNavigate();
    const handleBackClick = () => {
        navigate('/');
    };

    return (
        <div className="min-h-screen bg-gray-50 p-6">
            <div className="max-w-4xl mx-auto bg-white shadow-md rounded-lg p-6">
                <button
                    onClick={handleBackClick}
                    className="mb-4 px-4 py-2 text-sm font-medium text-white bg-blue-500 rounded hover:bg-blue-600"
                >
                    Back to Home
                </button>
                <h1 className="text-3xl font-bold text-gray-800 mb-6">Terms of Use</h1>
                <div className="space-y-6">
                    <section>
                        <p className="text-gray-600">
                            At Lexihero, accessible from{' '}
                            <a
                                href="https://lexihero.com"
                                className="text-blue-500 underline hover:text-blue-700"
                            >
                                https://lexihero.com
                            </a>
                            , one of our main priorities is the privacy of our visitors. This Privacy Policy document
                            contains types of information that is collected and recorded by Lexihero and how we use it.
                        </p>
                        <p className="text-gray-600 mt-2">
                            If you have additional questions or require more information about our Privacy Policy, do not
                            hesitate to contact us.
                        </p>
                        <p className="text-gray-600 mt-2">
                            This Privacy Policy applies only to our online activities and is valid for visitors to our
                            website with regards to the information that they shared and/or collect in Lexihero. This
                            policy is not applicable to any information collected offline or via channels other than this
                            website.
                        </p>
                    </section>
                    <section>
                        <h2 className="text-xl font-semibold text-gray-700">Consent</h2>
                        <p className="text-gray-600 mt-2">
                            By using our website, you hereby consent to our Privacy Policy and agree to its terms.
                        </p>
                    </section>
                    <section>
                        <h2 className="text-xl font-semibold text-gray-700">Information we collect</h2>
                        <p className="text-gray-600 mt-2">
                            The personal information that you are asked to provide, and the reasons why you are asked to
                            provide it, will be made clear to you at the point we ask you to provide your personal
                            information.
                        </p>
                        <p className="text-gray-600 mt-2">
                            If you contact us directly, we may receive additional information about you such as your name,
                            email address, phone number, the contents of the message and/or attachments you may send us,
                            and any other information you may choose to provide.
                        </p>
                        <p className="text-gray-600 mt-2">
                            When you register for an Account, we may ask for your contact information, including items such
                            as name, company name, address, email address, and telephone number.
                        </p>
                    </section>
                    <section>
                        <h2 className="text-xl font-semibold text-gray-700">How we use your information</h2>
                        <p className="text-gray-600 mt-2">We use the information we collect in various ways, including to:</p>
                        <ul className="list-disc list-inside mt-2 text-gray-600">
                            <li>Provide, operate, and maintain our website</li>
                            <li>Improve, personalize, and expand our website</li>
                            <li>Understand and analyze how you use our website</li>
                            <li>Develop new products, services, features, and functionality</li>
                            <li>
                                Communicate with you, either directly or through one of our partners, including for customer
                                service, to provide you with updates and other information relating to the website, and for
                                marketing and promotional purposes
                            </li>
                            <li>Send you emails</li>
                            <li>Find and prevent fraud</li>
                        </ul>
                    </section>
                    <section>
                        <h2 className="text-xl font-semibold text-gray-700">Log Files</h2>
                        <p className="text-gray-600 mt-2">
                            Lexihero follows a standard procedure of using log files. These files log visitors when they
                            visit websites. All hosting companies do this and a part of hosting services' analytics. The
                            information collected by log files include internet protocol (IP) addresses, browser type,
                            Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the
                            number of clicks. These are not linked to any information that is personally identifiable. The
                            purpose of the information is for analyzing trends, administering the site, tracking users'
                            movement on the website, and gathering demographic information.
                        </p>
                    </section>
                    <section>
                        <h2 className="text-xl font-semibold text-gray-700">Cookies and Web Beacons</h2>
                        <p className="text-gray-600 mt-2">
                            Like any other website, Lexihero uses "cookies". These cookies are used to store information
                            including visitors' preferences, and the pages on the website that the visitor accessed or
                            visited. The information is used to optimize the users' experience by customizing our web page
                            content based on visitors' browser type and/or other information.
                        </p>
                    </section>
                    <section>
                        <h2 className="text-xl font-semibold text-gray-700">Children's Information</h2>
                        <p className="text-gray-600 mt-2">
                            Another part of our priority is adding protection for children while using the internet. We
                            encourage parents and guardians to observe, participate in, and/or monitor and guide their
                            online activity.
                        </p>
                        <p className="text-gray-600 mt-2">
                            Lexihero does not knowingly collect any Personal Identifiable Information from children under
                            the age of 13. If you think that your child provided this kind of information on our website, we
                            strongly encourage you to contact us immediately and we will do our best efforts to promptly
                            remove such information from our records.
                        </p>
                    </section>
                </div>
            </div>
        </div>
    );
}

export default TermsOfUse;
