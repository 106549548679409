// src/NotFoundPage.js
import React from 'react';
import { useTranslation } from 'react-i18next';

const NotFoundPage = () => {
    const { t } = useTranslation(); // Access the translation function

    return (
        <section className="bg-white">
            <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
                <div className="mx-auto max-w-screen-sm text-center">
                    <h1 className="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-primary-600">{t('notFound.title')}</h1>
                    <p className="mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl">{t('notFound.message')}</p>
                    <p className="mb-4 text-lg font-light text-gray-500">{t('notFound.description')}</p>
                    <a 
                        href="/" 
                        className="inline-flex justify-center items-center text-white bg-indigo-600 hover:bg-indigo-500 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center my-4"
                    >
                        {t('notFound.backToHomepage')}
                    </a>
                </div>
            </div>
        </section>
    );
};

export default NotFoundPage;
