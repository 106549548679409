import { BrowserRouter as Router, Navigate, Route, Routes } from "react-router-dom";
import AuthForm from "./auth";
import MainPage from "./main";
import SavedTranslations from "./saved_translations";
import PrivacyPolicy from "./privacy_policy";
import TermsOfUse from "./terms_of_use";
import NotFoundPage from "./not_found_page";
import isUserAuthz from "./is_user_auth";
import { useEffect } from "react";
import Cookies from "js-cookie";
import CookieConsent from "react-cookie-consent";
import ReactGA from "react-ga4";
import { useTranslation } from 'react-i18next';


export default function App() {
    const { t } = useTranslation();

    useEffect(() => {
        const consent = Cookies.get('cookies_accepted');
        if (consent === 'true') {
            ReactGA.initialize("G-94R3JQF9V0");
        }
    }, []);

    const handleAccept = () => {
        Cookies.set('cookies_accepted', 'true', { expires: 90 });
        ReactGA.initialize("G-94R3JQF9V0");
    };

    const handleDecline = () => {
        Cookies.set('cookies_accepted', 'false', { expires: 2 });
        ReactGA.disable();
    };

    return (
        <>
            <CookieConsent
                enableDeclineButton
                location="bottom"
                buttonText="Accept All"
                cookieName="cookies_accepted"
                style={{
                    justifyContent: "center",
                    alignItems: "center",
                    background: "white",
                    color: "black",
                    textAlign: "center",
                    borderRadius: "0.375rem",
                    border: "1px solid",
                    margin: "10px auto",
                    width: "100%",
                    boxSizing: "border-box",
                    paddingTop: "0.375rem",
                    paddingBottom: "0.375rem",
                    paddingLeft: "0.375rem",
                    paddingRight: "0.375rem",
                    borderColor: "#e5e7eb",
                }}
                declineButtonStyle={{
                    display: "inline-block",
                    borderRadius: "0.375rem",
                    backgroundColor: "white",
                    color: "black",
                    border: "1px solid",
                    paddingTop: "0.375rem",
                    paddingBottom: "0.375rem",
                    paddingLeft: "0.375rem",
                    paddingRight: "0.375rem",
                    borderColor: "#e5e7eb",
                }}
                buttonStyle={{
                    display: "inline-block",
                    borderRadius: "0.375rem",
                    backgroundColor: "black",
                    color: "white",
                    paddingTop: "0.375rem",
                    paddingBottom: "0.375rem",
                    paddingLeft: "0.375rem",
                    paddingRight: "0.375rem",
                }}
                declineButtonText={"Reject non-essential"}
                expires={365}
                onAccept={handleAccept}
                onDecline={handleDecline}
            >
                Check our <a href="/privacy-policy" className="text-indigo- hover:underline">Privacy Policy</a> and <a href="/terms-of-use" className="text-indigo- hover:underline">Terms of Use</a> for details. Click 'Accept all' to let LexiHero use cookies for these purposes. Click 'Reject non-essential' to say no to cookies, except those that are strictly necessary.
            </CookieConsent>

            <Router>
                <Routes>
                    <Route path="/auth" element={<AuthForm />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="/terms-of-use" element={<TermsOfUse />} />
                    <Route path="/" element={<ProtectedRoute><MainPage /></ProtectedRoute>} />
                    <Route path="/saved-translations" element={<ProtectedRoute><SavedTranslations /></ProtectedRoute>} />
                    {/* Add a catch-all route for 404 */}
                    <Route path="*" element={<NotFoundPage />} />
                </Routes>
            </Router>

        </>
    );
}

function ProtectedRoute({ children }) {
    if (!isUserAuthz()) {
        return <Navigate to="/auth" replace />;
    }
    return children;
}
